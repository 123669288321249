import React from "react"
import api from "../lib/api"
import { messages } from "../lib/messages"
import Layout from "../components/layout"
import Header from "../components/header"
import PageTitle from "../components/page-title"
import Icon from "../components/icon"
import { ContactForm } from "../components/forms"
import { BlackButton } from "../components/black-buttons"
import { Link } from "gatsby"
import "../style.css"

class FourOhFourPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			phone: '',
			message: ''
		}
		this.emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
	}
	updateField = (event) => {
		const new_value = event.target.value;
		this.setState({ [event.target.name]: new_value });
	}
	emailValid = () => {
		return this.emailRegex.test(this.state.email);
	}
	valid = () => {
		const s = this.state;
		return s.message.length > 2 && s.name.length > 2 && this.emailValid();
	}
	submit = async () => {
		if(this.valid()) {
			try {
				let req = { ...this.state };
				const response = await api.contactUs({ request: req });
				if(response.error) {
					messages.show('CONTACT_FAILED');
					return;
				}
				messages.show('CONTACT_SENT');
			} catch(e) {
				messages.show('CONTACT_FAILED');
			}
		}
	}
	render() {
		return (
			<Layout>
				<div className='ph3 ph4-ns pt3 pt0-ns cf'>

					<PageTitle title="Page Not Found" />

					<div className='w-100 w-100-s w-50-ns fl pr5-m pr5-l pr5-xl pb4'>
						<div>
							<p className='lh-copy black-90 f6 f5-ns'>We're sorry, but the page you're looking for cannot be found.</p>
							<p className='lh-copy black-90 f6 f5-ns'>If you believe this is an error, please don't hesitate to <Link className='blue no-underline hover-near-black' to="/contact">contact us</Link> and let us know.</p>
						</div>
					</div>

				</div>
			</Layout>
		)
	}
}

export default FourOhFourPage;